<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-back-button text=""></ion-back-button>
        </ion-buttons>
        <ion-title>{{$t('solidaryTransport.volunteer.title')}} <sup>1/5</sup></ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content ref="request" color="primary" class="is-scrollable">
      <div class="mc-st-container">
        <div class="mc-st-form">

          <div class="mc-st-form-content">
            <div class="mc-st-form-header">
              <div class="mc-st-form-title">{{$t('solidaryTransport.volunteer.steps.intro')}}</div>
              <div class="mc-st-form-steps">
                <span class="mc-st-form-step is-active"></span>
                <span class="mc-st-form-step"></span>
                <span class="mc-st-form-step"></span>
                <span class="mc-st-form-step"></span>
                <span class="mc-st-form-step"></span>
              </div>
            </div>

            <ion-item class="mc-st-form-item" v-on:click="displayGeoSearch()">
              <ion-label position="stacked">{{$t('solidaryTransport.volunteer.form.fields.address')}} *</ion-label>
              <ion-input
                type="text"
                name="address"
                :value="getAddressToDisplay(volunteer.homeAddress)"
                readonly="true"
                class="no-clickable"
              ></ion-input>
            </ion-item>
            <div class="mc-st-form-details" v-if="v$.volunteer.homeAddress.$error">
              <span class="mc-st-form-error" v-if="v$.volunteer.homeAddress.required.$invalid">{{$t('solidaryTransport.register.form.validators.required')}}</span>
            </div>
          </div>

          <div class="mc-st-form-controls with-multiple">
            <ion-button class="mc-st-form-control as-back" color="light" v-html="$t('solidaryTransport.buttons.back')" @click="$router.back()"></ion-button>

            <ion-button class="mc-st-form-control" color="success" v-html="$t('solidaryTransport.buttons.next')" @click="validate()"></ion-button>
          </div>

        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<style lang="scss"></style>

<script>
import _ from 'lodash'
import { mapState, mapGetters } from 'vuex'
import { required } from '@vuelidate/validators'
import useVuelidate from "@vuelidate/core";

export default {
  name: 'solidaryTransport.volunteer',
  setup () {
    return { v$: useVuelidate() }
  },
  components: {},
  data () {
    return {
      type: this.$route.meta.type
    }
  },
  computed: {
    ...mapGetters([
      'getAddressToDisplay'
    ]),
    user: {
      get() {
        return this.$store.state.userStore.user;
      }
    },
    volunteer: {
      get() {
        return this.$store.state.solidaryTransportStore.temporary.volunteer;
      },
      set() {
        this.$store.commit("solidaryTemporyVolunteerUpdate", this.volunteer);
      }
    }
  },
  validations() { return {
    volunteer: {
      homeAddress: {
        required
      }
    },
  }},
  methods: {
    displayGeoSearch: function () {
      this.$router.push({ name: (this.$route.name.includes('carpool') ? '' : 'solidaryTransport.') + 'geoSearch', query: { action: 'solidaryTransport.search', type: 'volunteer.home' }});
    },
    validate: function () {
      this.v$.$reset();
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.$refs.request.getScrollElement().then((parent) => {
          let child = document.getElementsByClassName('mc-st-form-error')[0]
          var childPos = child.offsetTop
          var parentPos = parent.offsetTop
          var top = childPos - parentPos - 30
          this.$refs.request.scrollToPoint(0, top, 0)
        })
      } else {
        this.$router.push({name: (this.$route.name.includes('carpool') ? 'carpool.solidary' : 'solidaryTransport') + '.home.volunteer.check'})
      }
    }
  },
  created: function () {
    // this.$store.state.solidaryTransportStore.temporary.volunteer = _.cloneDeep(this.$store.state.solidaryTransportStore.default.volunteer);
    if (this.user) {
      if (!this.volunteer.homeAddress) {
        this.$store.commit("solidaryVolunteerHomeAddressUpdate", this.user.addresses[0])
      }
    }
  }
}
</script>
