<template>
  <ion-page>


  <!-- Listen to before and after tab change events -->
  <ion-tabs>

    <ion-router-outlet></ion-router-outlet>

      <ion-tab-bar slot="bottom" class="as-main">
        <ion-tab-button tab="home"  href="/solidary-transport/home">
          <ion-icon name="home"></ion-icon>
          <ion-label>{{ $t('solidaryTransport.navigation.home') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="help" href="/solidary-transport/help">
          <ion-icon name="help-circle"></ion-icon>
          <ion-label>{{ $t('solidaryTransport.navigation.help') }}</ion-label>
        </ion-tab-button>

        <template v-if="!this.$store.state.userStore.user">
          <ion-tab-button tab="register" href="/solidary-transport/register">
            <ion-icon name="create"></ion-icon>
            <ion-label>{{ $t('solidaryTransport.navigation.register') }}</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="login" href="/solidary-transport/login">
            <ion-icon name="person"></ion-icon>
            <ion-label>{{ $t('solidaryTransport.navigation.login') }}</ion-label>
          </ion-tab-button>
        </template>
        <template v-else>
          <ion-tab-button tab="messages" href="/solidary-transport/messages">
            <ion-icon name="chatbubbles"></ion-icon>
            <ion-label>{{ $t('solidaryTransport.navigation.messages') }}</ion-label>
            <!--<ion-badge color="warning">6</ion-badge>-->
          </ion-tab-button>

          <ion-tab-button tab="profile" href="/solidary-transport/profile">
            <ion-icon name="person"></ion-icon>
            <ion-label>{{ $t('solidaryTransport.navigation.profile') }}</ion-label>
          </ion-tab-button>
        </template>

      </ion-tab-bar>
  </ion-tabs>
  </ion-page>
</template>

<style lang="scss">

  ion-tab-bar.as-main {
    --border: none;

    ion-tab-button {
      --background: #F5F6FA;
      --color: var(--ion-color-primary) !important;
      --color-selected: var(--ion-color-primary) !important;

      &[tab="home"] {
        ion-icon {
          width: .7em;
          height: 1em;
        }
      }
    }

    ion-tab-button.tab-selected {
      --background: var(--ion-color-primary) !important;
      --color: var(--ion-color-light) !important;
      --color-selected: var(--ion-color-light) !important;
    }
  }
</style>

<script>
  export default {
    name: 'solidaryTransport.main',
    data () {
      return {
      }
    },
    methods: {}
  }
</script>
