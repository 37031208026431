<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-back-button text=""></ion-back-button>
        </ion-buttons>
        <ion-title>{{$t('solidaryTransport.' + type + '.title')}} <sup>4/6</sup></ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content ref="request" color="primary" class="is-scrollable">
      <div class="mc-st-container">
        <div class="mc-st-form">

          <div class="mc-st-form-content">
            <div class="mc-st-form-header">
              <div class="mc-st-form-title">{{$t('solidaryTransport.request.steps.punctual')}}</div>
              <div class="mc-st-form-steps">
                <span class="mc-st-form-step is-validate"></span>
                <span class="mc-st-form-step is-validate"></span>
                <span class="mc-st-form-step is-validate"></span>
                <span class="mc-st-form-step is-active"></span>
                <span class="mc-st-form-step"></span>
                <span class="mc-st-form-step"></span>
              </div>
            </div>

            <div class="mc-st-form-item">
              <ion-label class="mc-st-form-label as-title no-white-space" color="primary">{{$t('solidaryTransport.' + this.$route.meta.type + '.form.fields.when.departure.date')}}</ion-label>
              <div class="mc-st-form-details" v-if="v$.request.when.departure.$error">
                <span class="mc-st-form-error is-left" v-if="v$.request.when.departure.hasDate.$invalid">{{$t('solidaryTransport.' + this.$route.meta.type + '.form.validators.required')}}</span>
              </div>

              <div class="mc-st-form-checkbox-wrapper">
                <ion-item class="mc-st-form-item" lines="none">
                  <!--<ion-radio class="mc-st-form-checkbox no-clickable" slot="start" :value="undefined" :checked="request.when.departure.specificDate !== undefined || (request.when.departure.specificDate === undefined && request.when.departure.marginDate === undefined)" color="success"></ion-radio>-->
                  <ion-input type="date"
                             :id="'departureDate'"
                             :min="$moment().format('YYYY-MM-DD')"
                             :max="$moment().add(5, 'years').format('YYYY-MM-DD')"
                             :value="request.when.departure.specificDate"
                             @ionChange="changeDepartureSpecificDate($event.target.value)"
                             @ionFocus="changeDepartureSpecificDate($event.target.value)"> </ion-input>
                  <!--
                  <ion-datetime-button  slot="end" :datetime="'departureDate'"></ion-datetime-button>
                    <ion-modal :keep-contents-mounted="true">
                    <ion-datetime
                        show-default-buttons="true"
                        :id="'departureDate'"
                        ref="departure-date"
                        class="mc-st-form-input"
                        display-format="DD/MM/YYYY"
                        picker-format="DD/MM/YYYY"
                        :first-day-of-week="1"
                        presentation="date"
                        :min="$moment().format('YYYY-MM-DD')"
                        :max="$moment().add(5, 'years').format('YYYY-MM-DD')"
                        :cancel-text="$t('solidaryTransport.buttons.cancel')"
                        :done-text="$t('solidaryTransport.buttons.validate')"
                        :placeholder="$t('solidaryTransport.request.form.fields.when.departure.specificDate')"
                        :value="request.when.departure.specificDate"
                        @ionChange="changeDepartureSpecificDate($event.target.value)"
                        @ionFocus="changeDepartureSpecificDate($event.target.value)"
                    ></ion-datetime>
                   </ion-modal>
                   -->
                </ion-item>
              </div>
            </div>

            <div class="mc-st-form-item">
              <ion-label class="mc-st-form-label as-title no-white-space" color="primary">{{$t('solidaryTransport.' + this.$route.meta.type + '.form.fields.when.departure.hour')}}</ion-label>
              <div class="mc-st-form-details" v-if="v$.request.when.departure.$error">
                <span class="mc-st-form-error is-left" v-if="v$.request.when.departure.hasHour.$invalid">{{$t('solidaryTransport.' + this.$route.meta.type + '.form.validators.required')}}</span>
              </div>

              <div class="mc-st-form-checkbox-wrapper">
                <ion-radio-group :value="request.when.departure.marginHour" @ionChange="changeDepartureMarginHour($event.target.value)">
                <ion-item class="mc-st-form-item" lines="none">
                  <!-- :checked="request.when.departure.specificHour !== undefined || (request.when.departure.specificHour === undefined && request.when.departure.marginHour === undefined)" -->
                  <ion-radio class="mc-st-form-checkbox-first-line" slot="start" :value="undefined" ref="departure-radio" :checked="request.when.departure.specificHour !== undefined || (request.when.departure.specificHour === undefined && request.when.departure.marginHour === undefined)" color="success"></ion-radio>
                  <ion-input type="time"
                             :id="'departureHour'"
                             :value="request.when.departure.specificHour"
                             @click="$refs['departure-radio'].$el.click()"
                             @ionChange="changeDepartureSpecificHour($event.target.value)"
                             @ionFocus="changeDepartureSpecificHour($event.target.value)"> </ion-input>
                  <!--
                  <ion-datetime-button ref="departure-hour" slot="end" :datetime="'departureHour'"></ion-datetime-button>
                    <ion-modal :keep-contents-mounted="true">
                    <ion-datetime
                    :id="'departureHour'"
                    show-default-buttons="true"
                    class="mc-st-form-input"
                    display-format="HH:mm"
                    picker-format="HH:mm"
                    :first-day-of-week="1"
                    presentation="time"
                    minute-values="0,5,10,15,20,25,30,35,40,45,50,55"
                    :cancel-text="$t('solidaryTransport.buttons.cancel')"
                    :done-text="$t('solidaryTransport.buttons.validate')"
                    :placeholder="$t('solidaryTransport.request.form.fields.when.departure.specificHour')"
                    :value="request.when.departure.specificHour"
                    @ionChange="changeDepartureSpecificHour($event.target.value)"
                    @ionFocus="changeDepartureSpecificHour($event.target.value)"
                  ></ion-datetime>
                   </ion-modal>
                   -->
                </ion-item>
                <ion-item class="mc-st-form-item" lines="none" v-for="(item, index) in departureHours" :key="index">
                  <ion-radio label-placement="end" class="mc-st-form-checkbox" :value="item.value" :checked="request.when.departure.marginHour === item.value" color="success" slot="start"
                  >
                    <ion-label class="mc-st-form-label no-white-space" color="primary">Entre {{ moment(item.min_time, 'HH', 'utc') }}h et {{ moment(item.max_time, 'HH', 'utc')}}h</ion-label>
                  </ion-radio>
                </ion-item>
                </ion-radio-group>
              </div>
            </div>

            <div class="mc-st-form-item">
              <ion-label class="mc-st-form-label as-title no-white-space" color="primary">{{$t('solidaryTransport.' + this.$route.meta.type + '.form.fields.when.return.hour')}}</ion-label>
              <div class="mc-st-form-details" v-if="v$.request.when.return.$error">
                <span class="mc-st-form-error is-left" v-if="v$.request.when.return.hasHour.$invalid">{{$t('solidaryTransport.' + this.$route.meta.type + '.form.validators.required')}}</span>
              </div>

              <div class="mc-st-form-checkbox-wrapper">
                <ion-radio-group :value="request.when.return.marginHour" @ionChange="changeReturnMarginHour($event.target.value)">
                <ion-item class="mc-st-form-item" lines="none">
                  <!-- :checked="request.when.return.specificHour !== undefined" -->
                  <ion-radio class="mc-st-form-checkbox-first-line" slot="start" :value="undefined" ref="return-radio"  color="success"></ion-radio>
                  <ion-input type="time"
                             :id="'returnHour'"
                             :value="request.when.return.specificHour"
                             @click="$refs['return-radio'].$el.click()"
                             @ionChange="changeReturnSpecificHour($event.target.value)"
                             @ionFocus="changeReturnSpecificHour($event.target.value)"> </ion-input>
                  <!--
                  <ion-datetime-button  slot="end" :datetime="'returnHour'"></ion-datetime-button>
                    <ion-modal :keep-contents-mounted="true">
                    <ion-datetime
                    :id="'returnHour'"
                    ref="return-hour"
                    class="mc-st-form-input"
                    show-default-buttons="true"
                    display-format="HH:mm"
                    picker-format="HH:mm"
                    :first-day-of-week="1"
                    presentation="time"
                    minute-values="0,5,10,15,20,25,30,35,40,45,50,55"
                    :cancel-text="$t('solidaryTransport.buttons.cancel')"
                    :done-text="$t('solidaryTransport.buttons.validate')"
                    :placeholder="$t('solidaryTransport.request.form.fields.when.return.specificHour')"
                    :value="request.when.return.specificHour"
                    @ionChange="changeReturnSpecificHour($event.target.value)"
                    @ionFocus="changeReturnSpecificHour($event.target.value)"
                  ></ion-datetime>
                  </ion-modal>
                  -->
                </ion-item>
                <ion-item class="mc-st-form-item" lines="none" v-for="(item, index) in returnHours" :key="index">
                  <ion-radio label-placement="end" class="mc-st-form-checkbox" :value="item.value" :checked="request.when.return.marginHour === item.value" color="success" slot="start"
                  >
                    <ion-label class="mc-st-form-label no-white-space" color="primary"> Entre {{ moment(item.min_time, 'HH', 'utc') }}h et {{ moment(item.max_time, 'HH', 'utc') }}h</ion-label>
                  </ion-radio>
                  <ion-label class="mc-st-form-label no-white-space" color="primary"></ion-label>
                </ion-item>
                  <ion-item class="mc-st-form-item" lines="none" v-if="type == 'request'" @click="noHour()">
                    <ion-radio label-placement="end" class="mc-st-form-checkbox" :value="undefined" :checked="request.when.return.marginHour === undefined && request.when.return.specificHour === undefined" color="success" slot="start"
                    >
                      <ion-label class="mc-st-form-label no-white-space" color="primary">{{$t('solidaryTransport.' + this.$route.meta.type + '.form.fields.when.return.noHour')}}</ion-label>
                    </ion-radio>
                  </ion-item>
                </ion-radio-group>
              </div>
            </div>

          </div>

          <div class="mc-st-form-controls with-multiple">
            <ion-button class="mc-st-form-control as-back" color="light" v-html="$t('solidaryTransport.buttons.back')" @click="$router.back()"></ion-button>

            <ion-button class="mc-st-form-control" color="success" v-html="$t('solidaryTransport.buttons.next')" @click="validate()"></ion-button>
          </div>

        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<style lang="scss"></style>

<script>
import _ from 'lodash'
import { mapState, mapGetters } from 'vuex'
import { required } from '@vuelidate/validators'
import { toast } from '../../Shared/Mixin/toast.mixin'
import moment from 'moment'
import useVuelidate from "@vuelidate/core";

const hasDate = (value) => {
  return !_.isEmpty(value.specificDate) || !_.isEmpty(value.marginDate)
}
const hasHour = (value) => {
    return !_.isEmpty(value.specificHour) || !_.isEmpty(value.marginHour)
}

export default {
  name: 'solidaryTransport.request.punctual',
  setup () {
    const v$ = useVuelidate();
    return { v$ }
  },
  components: {},
  data () {
    return {
      updating: false,
      type: this.$route.meta.type,
      departureDates: [
                {
                  "value": "this-week",
                  "label": "Dans la semaine"
                },
                {
                  "value": "in-two-weeks",
                  "label": "Dans les deux semaines"
                },
                {
                  "value": "in-month",
                  "label": "Dans le mois"
                }
              ],
      departureHours: [],
      returnHours: [],
    }
  },
  computed: {
    ...mapGetters([
      'getAddressToDisplay'
    ]),
    request: {
      get() {
        return this.$store.state.solidaryTransportStore.temporary.request;
      },
      set() {
        this.$store.commit("solidaryTemporyRequestUpdate", this.request);
      }
    }
  },
  mixins: [toast],
  validations() { return {
    request: {
      when: {
        departure: {
          hasDate,
          hasHour
        },
        return: {
          hasHour(value) {
            if (this.type == 'request') {
              return true;
            } else {
              return hasHour(value);
            }
          }
        }
      }
    }
  }},
  methods: {
    noHour() {
      if (!this.updating) {
        this.updating = true
        this.request.when.return.marginHour = undefined;
        this.request.when.return.specificHour = undefined

        setTimeout(() => {
          this.updating = false
        }, 100)
      }
    },
    changeDepartureMarginDate(value) {
      if (!this.updating) {
        this.updating = true
        this.request.when.departure.specificDate = undefined
        this.request.when.departure.marginDate = value

        setTimeout(() => {
          this.updating = false
        }, 100)
      }
    },
    changeDepartureSpecificDate(value) {
      if (!this.updating) {
        this.updating = true
        this.request.when.departure.marginDate = undefined
        this.request.when.departure.specificDate = value

        setTimeout(() => {
          this.updating = false
        }, 100)
      }
    },
    changeDepartureMarginHour(value) {
      if (!this.updating) {
        this.updating = true
        if (value) {
          this.request.when.departure.specificHour = undefined
        }
        this.request.when.departure.marginHour = value

        setTimeout(() => {
          this.updating = false
        }, 100)
      }
    },
    changeDepartureSpecificHour(value) {
      console.log(value)
      if (!this.updating) {
        this.updating = true

        this.request.when.departure.marginHour = undefined
        this.request.when.departure.specificHour = value
        console.log(this.request.when.departure.marginHour)

        setTimeout(() => {
          this.updating = false
        }, 100)
      }
    },
    changeReturnMarginHour(value) {
      if (!this.updating) {
        this.updating = true
        if (value) {
          this.request.when.return.specificHour = undefined
        }
        this.request.when.return.marginHour = value

        setTimeout(() => {
          this.updating = false
        }, 100)
      }
    },
    changeReturnSpecificHour(value) {
      if (!this.updating) {
        this.updating = true
        this.request.when.return.marginHour = undefined
        this.request.when.return.specificHour = value

        setTimeout(() => {
          this.updating = false
        }, 100)
      }
    },
    displayGeoSearchForOrigin() {
      this.$router.push({ name: (this.$route.name.includes('carpool') ? '' : 'solidaryTransport.') + 'geoSearch', query: { action: 'solidaryTransport.search', type: 'request.origin' }});
    },
    displayGeoSearchForDestination() {
      this.$router.push({ name:(this.$route.name.includes('carpool') ? '' : 'solidaryTransport.') + 'geoSearch', query: { action: 'solidaryTransport.search', type: 'request.destination' }});
    },
    validate() {
      this.v$.$reset();
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.$refs.request.$el.getScrollElement().then((parent) => {
          let child = document.getElementsByClassName('mc-st-form-error')[0]
          var childPos = child.offsetTop
          var parentPos = parent.offsetTop
          var top = childPos - parentPos - 30
          this.$refs.request.$el.scrollToPoint(0, top, 0)
        })
      } else {
        this.$router.push({name: (this.$route.name.includes('carpool') ? 'carpool.solidary' : 'solidaryTransport') + '.home.' + this.type + '.user'})
      }
    }
  },
  created: function () {
    this.departureHours.push({'value': 'morning', 'min_time': moment.utc(this.request.structure.mMinTime), 'max_time': moment.utc(this.request.structure.mMaxTime)});
    this.departureHours.push({'value': 'afternoon', 'min_time': moment.utc(this.request.structure.aMinTime), 'max_time': moment.utc(this.request.structure.aMaxTime)});
    this.departureHours.push({'value': 'evening', 'min_time': moment.utc(this.request.structure.eMinTime), 'max_time': moment.utc(this.request.structure.eMaxTime)});
    this.returnHours.push({'value': 'morning','min_time': moment.utc(this.request.structure.mMinTime), 'max_time': moment.utc(this.request.structure.mMaxTime)});
    this.returnHours.push({'value': 'afternoon', 'min_time': moment.utc(this.request.structure.aMinTime), 'max_time': moment.utc(this.request.structure.aMaxTime)});
    this.returnHours.push({'value': 'evening', 'min_time': moment.utc(this.request.structure.eMinTime), 'max_time': moment.utc(this.request.structure.eMaxTime)});
  },
  mounted: function () {

  }
}
</script>
