<template>
  <div class="ion-page">
    <ion-header no-border>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <h1 class="ion-text-center">Autorisation Parentale</h1>
      </ion-toolbar>
    </ion-header>

    <ion-content color="primary" no-bounce>
      <div class="mc-white-container">
        <div class="ion-text-center ion-margin-top" v-if="loader">
          <ion-icon size="large" color="primary" class="rotating" name="md-sync"></ion-icon>
        </div>
        <div v-if="!loader">
          <div v-if="!user">
            <div style="font-size: 14px">
              Veuillez renseigner le code d'autorisation parentale présent dans le mail que vous avez reçu.
            </div>

            <ion-item>
              <ion-label position="stacked">Code</ion-label>
              <ion-input
                type="text"
                name="code"
                :value="code"
                @input="code = $event.target.value"
                placeholder="XXXXXX"
              ></ion-input>
            </ion-item>
            <br>
            <ion-button @click="getUserUnderEighteen()" type="button" class="mc-small-button" expand="block">
              Donner mon autorisation parentale
            </ion-button>
          </div>
          <div v-if="user">
            <div v-if="user.parentalConsentDate">
              <div style="font-size: 14px">
                Vous avez donné votre autorisation parentale à <b>{{user.givenName}} {{user.familyName}}</b> en date du {{ moment(user.parentalConsentDate, "DD/MM/YY","utc") }}.
              </div>
              <br>
              <ion-button @click="$router.push({name: 'carpoolsHome'})" type="button" class="mc-small-button" color="danger" expand="block">
                Fermer
              </ion-button>
            </div>
            <div v-if="!user.parentalConsentDate">
              <div style="font-size: 14px">
                <ion-item lines="none">
                  <ion-checkbox v-model="steps.first" @ionChange="steps.first = $event.detail.checked;" slot="start"></ion-checkbox>
                  <ion-label class="no-white-space">En tant que responsable légal.e du.de la mineur.e <b>{{user.givenName}} {{user.familyName}}</b>. Je déclare autoriser ce.cette mineur.e de plus de 14 ans dont j’ai la responsabilité à utiliser Mobicoop. J’ai bien noté que ce.cette mineur.e reste sous mon entière responsabilité.
                  </ion-label>
                </ion-item>
                <br>
                <ion-item lines="none" v-if="stepLength(1)">
                  <ion-checkbox slot="start" v-model="steps.second" @ionChange="steps.second = $event.detail.checked" >
                  </ion-checkbox>
                  <ion-label class="no-white-space">
                    En aucun cas, la SCIC Mobicoop, opérateur de la plateforme, ainsi que les collectivités partenaires de Mobicoop ne pourront être tenues responsables pour quelque dommage que ce soit.
                  </ion-label>
                </ion-item>
                <br>
                <ion-item lines="none" v-if="stepLength(2)">
                <ion-checkbox v-model="steps.third" @ionChange="steps.third = $event.detail.checked" slot="start">
                  </ion-checkbox>
                  <ion-label class="no-white-space">
                    Je m’engage à bien expliquer au.à la mineur.e dont j’ai la responsabilité le fonctionnement du dispositif et notamment à attirer son attention sur les conseils pour réussir ses déplacements dans la FAQ et les Bonnes Pratiques.
                  </ion-label>
                </ion-item>
                <br>
                <ion-item lines="none" v-if="stepLength(3)">
                <ion-checkbox  v-model="steps.four" @ionChange="steps.four = $event.detail.checked" slot="start">
                </ion-checkbox>
                  <ion-label class="no-white-space">
                    Mobicoop se réserve le droit de me contacter.
                  </ion-label>
                </ion-item>
                <br>
                <ion-item lines="none" v-if="stepLength(4)">
                <ion-checkbox v-model="steps.five" @ionChange="steps.five = $event.detail.checked" slot="start">
                </ion-checkbox>

                  <ion-label class="no-white-space">
                    En donnant mon autorisation parentale, je m'engage à respecter chaque paragraphe de celle-ci.
                  </ion-label>
                </ion-item>
                <br>
              </div>

              <ion-button v-if="stepLength(5)" @click="postUserUnderEighteen()" type="button" class="mc-small-button" expand="block">
                Autoriser
              </ion-button>
            </div>

          </div>
        </div>
      </div>
    </ion-content>
  </div>
</template>

<style lang="scss">
</style>
<script>

import CarpoolItem from "../Shared/Components/CarpoolItem.component.vue";
import {toast} from "../../Shared/Mixin/toast.mixin";

export default {
  name: 'autorisation-parentale',
  data () {
    return {
      code: '',
      steps: { first : false, second: false, third: false, four : false, five: false},
      uuid: this.$route.params.id,
      loader: false,
      user : null
    }
  },
  mixins: [toast],
  components: {

  },
  created() {
    console.log(this.$route)
  },
  computed: {

  },
  methods: {
    getUserUnderEighteen() {
      this.loader = true;
      this.$store
        .dispatch("getUserUnderEighteen", this.code)
        .then(res => {
          this.user = res.data;
          this.loader = false;
        }).catch(error => {
        this.loader = false;
        this.presentToast(this.$t("Commons.error"), "danger");
      })
    },
    stepLength(index) {
      console.log(index);
      console.log(this.steps);
      return Object.values(this.steps).slice(0, index).every(item => item == true)
    },
    postUserUnderEighteen() {
      this.loader = true;
      const params = {
        uuid : this.uuid,
        token : this.code
      }
      this.$store
        .dispatch("postUserUnderEighteen", params)
        .then(res => {
          this.getUserUnderEighteen();
        }).catch(error => {
        this.loader = false;
        this.presentToast(this.$t("Commons.error"), "danger");
      })
    }
  }
}
</script>
