import http from '../Shared/Mixin/http.mixin'
import { isPlatform } from "@ionic/core"
import _ from 'lodash'
import moment from 'moment'

let n = new Date();
n.setFullYear(n.getFullYear() - 18);

export const solidaryTransportStore = {
  state: {
    messages: {
      home: {
        display: true
      }
    },
    help: {
      article: undefined,
      status: '' // loading | success | error
    },
    structures: {
      objects: undefined,
      status: ''
    },
    solidaries: {
      objects: undefined
    },
    register: {
      status: ''
    },
    default: {
      user: {
        status: 1,
        givenName: undefined,
        familyName: undefined,
        email: undefined,
        gender: 0,
        birthDate: undefined,
        telephone: undefined,
        password: undefined,
        phoneDisplay: 1,
        mobile: true,
        addresses: [undefined],
        mobileRegistration: 1,
        userAgreementAccepted: false
      },
      request: {
        telephone: undefined,
        givenName: undefined,
        password: undefined,
        familyName: undefined,
        email: undefined,
        birthDate: undefined,
        userAgreementAccepted: false,
        gender: 0,
        homeAddress: undefined,
        subject: undefined,
        structure: undefined,
        status: 0,
        proofs: {
          mandatory: {},
          optional: {}
        },
        needs: [],
        outwardDeadlineDatetime: undefined,
        outwardDatetime: undefined,
        returnDeadlineDatetime: undefined,
        returnDatetime: undefined,
        marginDuration: 9000, // 2h30 before and after covered
        returnMarginDuration: 9000,
        origin: undefined,
        destination: undefined,
        frequency: 1,
        days: {
          mon: 0,
          tue: 0,
          wed: 0,
          thu: 0,
          fri: 0,
          sat: 0,
          sun: 0
        },
        when: {
          departure: {
            specificDate: undefined,
            marginDate: undefined,
            specificHour: undefined,
            marginHour: undefined
          },
          return: {
            specificDate: undefined,
            marginDate: undefined,
            specificHour: undefined,
            marginHour: undefined
          }
        }
      },
      volunteer: {
        userAgreementAccepted: false,
        languages: [],
        needs: [],
        comment: undefined,
        vehicle: true,
        telephone: undefined,
        givenName: undefined,
        password: undefined,
        familyName: undefined,
        email: undefined,
        birthDate: JSON.parse(process.env.VUE_APP_REQUIRED_BIRTHDATE) ? n.toISOString() : null,
        gender: 0,
        homeAddress: undefined,
        structure: undefined,
        hasStructure: true,
        mMin: 8,
        mMinTime: 8,
        mMax: 13,
        mMaxTime: 13,
        aMin: 13,
        aMinTime: 13,
        aMax: 18,
        aMaxTime: 18,
        eMin: 18,
        eMinTime: 18,
        eMax: 21,
        eMaxTime: 21,
        mMon: false,
        aMon: false,
        eMon: false,
        mTue: false,
        aTue: false,
        eTue: false,
        mWed: false,
        aWed: false,
        eWed: false,
        mThu: false,
        aThu: false,
        eThu: false,
        mFri: false,
        aFri: false,
        eFri: false,
        mSat: false,
        aSat: false,
        eSat: false,
        mSun: false,
        aSun: false,
        eSun: false,
        minDeviationDistance: 5,
        maxDeviationDistance: 50,
        maxDistance: 5
      }
    },
    temporary: {
      user: {
        status: 1,
        givenName: undefined,
        familyName: undefined,
        email: undefined,
        gender: 0,
        birthDate: undefined,
        telephone: undefined,
        password: undefined,
        phoneDisplay: 1,
        mobile: true,
        addresses: [undefined],
        mobileRegistration: 1,
        userAgreementAccepted: false
      },
      address: {},
      request: {
        telephone: undefined,
        givenName: undefined,
        password: undefined,
        familyName: undefined,
        email: undefined,
        birthDate: undefined,
        userAgreementAccepted: false,
        gender: 0,
        homeAddress: undefined,
        subject: undefined,
        structure: undefined,
        status: 0,
        proofs: {
          mandatory: {},
          optional: {}
        },
        needs: [],
        outwardDeadlineDatetime: undefined,
        outwardDatetime: undefined,
        returnDeadlineDatetime: undefined,
        returnDatetime: undefined,
        marginDuration: 9000, // 2h30 before and after covered
        returnMarginDuration: 9000,
        origin: undefined,
        destination: undefined,
        frequency: 1,
        days: {
          mon: 0,
          tue: 0,
          wed: 0,
          thu: 0,
          fri: 0,
          sat: 0,
          sun: 0
        },
        when: {
          departure: {
            specificDate: undefined,
            marginDate: undefined,
            specificHour: undefined,
            marginHour: undefined
          },
          return: {
            specificDate: undefined,
            marginDate: undefined,
            specificHour: undefined,
            marginHour: undefined
          }
        }
      },
      volunteer: {
        userAgreementAccepted: false,
        languages: [],
        needs: [],
        comment: undefined,
        vehicle: true,
        telephone: undefined,
        givenName: undefined,
        password: undefined,
        familyName: undefined,
        email: undefined,
        birthDate: undefined,
        gender: 0,
        homeAddress: undefined,
        structure: undefined,
        hasStructure: true,
        mMin: 8,
        mMinTime: 8,
        mMax: 13,
        mMaxTime: 13,
        aMin: 13,
        aMinTime: 13,
        aMax: 18,
        aMaxTime: 18,
        eMin: 18,
        eMinTime: 18,
        eMax: 21,
        eMaxTime: 21,
        mMon: false,
        aMon: false,
        eMon: false,
        mTue: false,
        aTue: false,
        eTue: false,
        mWed: false,
        aWed: false,
        eWed: false,
        mThu: false,
        aThu: false,
        eThu: false,
        mFri: false,
        aFri: false,
        eFri: false,
        mSat: false,
        aSat: false,
        eSat: false,
        mSun: false,
        aSun: false,
        eSun: false,
        minDeviationDistance: 5,
        maxDeviationDistance: 50,
        maxDistance: 5
      }
    }
  },
  mutations: {
    solidaryUpdateMessageDisplayOnHome(state, display) {
      state.messages.home.display = display
    },

    // Help Mutations below
    solidaryHelpRequest(state) {
      state.help.status = 'loading';
    },

    solidaryHelpSuccess(state, article) {
      state.help.status = 'success';
      state.help.article = article
    },

    solidaryHelpError(state) {
      state.help.status = 'error';
    },

    // Register Mutations below
    solidaryRegisterUserRequest(state) {
      state.register.status = 'loading';
    },

    solidaryRegisterUserSuccess(state, user) {
      state.register.status = 'success';
      state.temporary.user = _.cloneDeep(state.default.user)
    },

    solidaryRegisterUserError(state) {
      state.register.status = 'error';
    },

    solidaryUserUpdate(state, user) {
      state.temporary.user = user
    },
    solidaryAddressUpdate(state, address) {
      state.temporary.address = address
    },
    solidaryUserAddressUpdate(state, address) {
      address = _.cloneDeep(address)
      // Remove useless elements
      delete address['@id']
      delete address['@type']
      delete address['id']
      delete address['geoJson']
      state.temporary.user.addresses.splice(0, 1, address)
    },

    // Request Mutations below
    postSolidaryResourceSuccess: (state) => {
      setTimeout(() => {
        state.temporary.request = _.cloneDeep(state.default.request)
      }, 1000);
    },

    solidaryRequestFrequencyChange(state) {
      state.temporary.request.when = _.cloneDeep(state.default.request.when)
      state.temporary.request.days = _.cloneDeep(state.default.request.days)
    },

    solidaryStructuresRequest(state) {
      state.structures.status = 'loading';
    },

    solidaryStructuresSuccess(state, structures) {
      state.structures.status = 'success';
      state.structures.objects = structures
    },

    mySolidariesSuccess(state, solidaries) {
      state.solidaries.objects = solidaries
    },

    solidaryStructuresError(state) {
      state.structures.status = 'error';
    },

    solidaryStructureUpdate(state, structure) {
      state.temporary.request.structure = structure

      // Proofs
      state.temporary.request.proofs = {}
      let mandatory = {}
      let optional = {}
      _.each(structure.structureProofs, (proof) => {
        let structureProof = {
          id: proof.id,
          askType: proof.type,
          value: undefined,
        }

        if (proof.checkbox) {
          structureProof.type = "checkbox"
        }
        if (proof.input) {
          structureProof.type = "input"
        }
        if (proof.selectbox) {
          structureProof.type = "selectbox"
          structureProof.options = {}

          let keys = _.split(proof.acceptedValues, ';')
          let values = _.split(proof.options, ';')

          _.each(keys, (key, index) => {
            structureProof.options[key] = values[index]
          })
        }
        if (proof.radio) {
          structureProof.type = "radio"
          structureProof.options = {}

          let keys = _.split(proof.acceptedValues, ';')
          let values = _.split(proof.options, ';')

          _.each(keys, (key, index) => {
            structureProof.options[key] = values[index]
          })
        }
        if (proof.file) {
          structureProof.type = "file"
          structureProof.file = undefined
        }

        if (proof.mandatory) {
          mandatory[proof.id] = structureProof
        } else {
          optional[proof.id] = structureProof
        }
      })
      state.temporary.request.proofs.mandatory = mandatory
      state.temporary.request.proofs.optional = optional

      // Needs
      state.temporary.request.needs = []
      _.each(structure.needs, (need) => {
        let structureNeed = {
          id: need.id,
          value: undefined,
        }
        state.temporary.request.needs.push(structureNeed)
      })

      // Subjects
      if (!!structure.subjects && structure.subjects.length !== 0) {
        state.temporary.request.subject = structure.subjects[0].id
      }
    },
    solidaryRequestHomeAddressUpdate(state, address) {
      address = _.cloneDeep(address)
      // Remove useless elements
      delete address['@id']
      delete address['@type']
      delete address['id']
      delete address['geoJson']
      state.temporary.request.homeAddress = address
    },
    solidaryRequestOriginAddressUpdate(state, address) {
      address = _.cloneDeep(address)
      // Remove useless elements
      delete address['@id']
      delete address['@type']
      delete address['id']
      delete address['geoJson']
      state.temporary.request.origin = address
    },
    solidaryRequestDestinationAddressUpdate(state, address) {
      address = _.cloneDeep(address)
      // Remove useless elements
      delete address['@id']
      delete address['@type']
      delete address['id']
      delete address['geoJson']
      state.temporary.request.destination = address
    },

    resetSolidaryUser: (state) => {
      state.temporary.user = _.cloneDeep(state.default.user)
    },

    resetSolidaryVolunteer: (state) => {
      state.temporary.volunteer = _.cloneDeep(state.default.volunteer)
    },

    resetSolidaryRequest: (state) => {
      state.temporary.request = _.cloneDeep(state.default.request)
    },


    // Volunteer
    postSolidaryVolunteerSuccess: (state, res) => {
      state.volunteer = res;
      if ( state.volunteer && state.volunteer.maxDistance ) {
        state.volunteer.maxDistance = state.volunteer.maxDistance / 1000;
      }

      setTimeout(() => {
        state.temporary.volunteer = _.cloneDeep(state.default.volunteer)
      }, 1000);

    },

    getSolidaryUserSuccess: (state, res) => {
      state.volunteer = res;
      if ( state.volunteer && state.volunteer.maxDistance ) {
        state.volunteer.maxDistance = state.volunteer.maxDistance / 1000;
      }
      state.temporary.volunteer = _.cloneDeep(state.default.volunteer)
    },

    putSolidaryVolunteerSuccess: (state, res) => {
      state.volunteer = res;
      if ( state.volunteer && state.volunteer.maxDistance ) {
        state.volunteer.maxDistance = state.volunteer.maxDistance / 1000;
      }
      state.temporary.volunteer = _.cloneDeep(state.default.volunteer)
    },

    solidaryVolunteerUpdate: (state, res) => {
      state.volunteer = res;
    },

    solidaryTemporyVolunteerUpdate: (state, res) => {
      state.temporary.volunteer = res;
    },

    solidaryTemporyRequestUpdate: (state, res) => {
      state.temporary.request = res;
    },

    solidaryVolunteerHomeAddressUpdate(state, address) {
      address = _.cloneDeep(address)
      // Remove useless elements
      delete address['@id']
      delete address['@type']
      delete address['id']
      delete address['geoJson']
      state.temporary.volunteer.homeAddress = address
    },
    solidaryVolunteerStructureUpdate(state, structure) {
      state.temporary.volunteer.structure = structure

      // Proofs
      state.temporary.volunteer.proofs = {}
      let mandatory = {}
      let optional = {}
      _.each(structure.structureProofs, (proof) => {
        let structureProof = {
          id: proof.id,
          askType: proof.type,
          value: undefined,
        }

        if (proof.checkbox) {
          structureProof.type = "checkbox"
        }
        if (proof.input) {
          structureProof.type = "input"
        }
        if (proof.selectbox) {
          structureProof.type = "selectbox"
          structureProof.options = {}

          let keys = _.split(proof.acceptedValues, ';')
          let values = _.split(proof.options, ';')

          _.each(keys, (key, index) => {
            structureProof.options[key] = values[index]
          })
        }
        if (proof.radio) {
          structureProof.type = "radio"
          structureProof.options = {}

          let keys = _.split(proof.acceptedValues, ';')
          let values = _.split(proof.options, ';')

          _.each(keys, (key, index) => {
            structureProof.options[key] = values[index]
          })
        }
        if (proof.file) {
          structureProof.type = "file"
          structureProof.file = undefined
        }

          if (proof.mandatory) {
            mandatory[proof.id] = structureProof
          } else {
            optional[proof.id] = structureProof
          }
      })
      state.temporary.volunteer.proofs.mandatory = mandatory
      state.temporary.volunteer.proofs.optional = optional

      // Needs
      state.temporary.volunteer.needs = []
      if (structure) {
        _.each(structure.needs, (need) => {
          let structureNeed = {
            id: need.id,
            value: undefined,
          }
          state.temporary.volunteer.needs.push(structureNeed)
        })
      }
    },
  },
  actions: {
    getSolidaryArticle({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        http.get("/articles/" + id)
          .then(response => {
            resolve(response.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getSolidaryStructuresByGeolocation({ commit, state }, { lat, lng }) {
      // if (state.structures.status === 'success') {
      //   return new Promise((resolve, reject) => {
      //     resolve(state.structures.objects)
      //   })
      // } else {
      commit('solidaryStructuresRequest')
      return new Promise((resolve, reject) => {
        http.get(`/structures/geolocation?lat=${lat}&lon=${lng}`)
          .then(response => {
            if (response) {
              commit('solidaryStructuresSuccess', response.data['hydra:member'])
              resolve(state.structures.objects)
            }
          })
          .catch(err => {
            commit('solidaryStructuresError')
            reject(err)
          })
      })
      //}
    },
    getSolidaryStructures({ commit, state }) {
      // if (state.structures.status === 'success') {
      //   return new Promise((resolve, reject) => {
      //     resolve(state.structures.objects)
      //   })
      // } else {
      commit('solidaryStructuresRequest')
      return new Promise((resolve, reject) => {
        http.get(`/structures`) // quick fix below
          //http.get(`/structures/geolocation?lat=48.858612&lon=2.339162`)
          .then(response => {
            if (response) {
              commit('solidaryStructuresSuccess', response.data['hydra:member'])
              resolve(state.structures.objects)
            }
          })
          .catch(err => {
            commit('solidaryStructuresError')
            reject(err)
          })
      })
      //}
    },
    getMySolidaries({ commit, state }) {
      return new Promise((resolve, reject) => {
        http.get(`/solidaries/mySolidaries`)
          .then(response => {
            if (response) {
              commit('mySolidariesSuccess', response.data['hydra:member'])
              resolve(state.solidaries.objects)
            }
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getSolidary({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        let solidary = _.find(state.solidaries.objects, { id: parseInt(id) })
        if (!_.isUndefined(solidary)) {
          resolve(solidary)
        } else {
          reject('Solidary not found')
        }
      })
    },
    getVolunteerDetails({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        http.get(`/solidary_volunteers/` + id)
          .then(response => {
            if (response) {
              const data = response.data;
              commit('getSolidaryUserSuccess', data);
              resolve(data)
            }
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    registerStandardUser: ({ commit, state }) => {
      let user = _.cloneDeep(state.temporary.user)
      commit('solidaryRegisterUserRequest')

      // Check Platform type for Marketing information
      if (isPlatform(window.document.defaultView, "ios")) {
        user.mobileRegistration = 2;
      }
      if (isPlatform(window.document.defaultView, "android")) {
        user.mobileRegistration = 3;
      }
      user.backLink = process.env.VUE_APP_DOMAIN + '/#/solidary-transport/register/success?email=' + user.email + '&token=';
      // Register user
      return new Promise((resolve, reject) => {
        http.post("/users/register", user).then(resp => {
          if (resp) {
            commit('solidaryRegisterUserSuccess', resp.data);
            resolve(resp.data)
          }
        }).catch(err => {
          commit('solidaryRegisterUserError');
          reject(err)
        })
      })
    },
    validateStandardUser: ({ commit, state }, params) => {
      let mobile = '';
      if (isPlatform(window.document.defaultView, "ios")) {
        mobile = '?mobile=1'
      }
      if (isPlatform(window.document.defaultView, "android")) {
        mobile = '?mobile=2'
      }

      return new Promise((resolve, reject) => {
        http.post("/login-token" + mobile, params).then(resp => {
          if (resp) {
            const tokenUser = resp.data.token;
            localStorage.setItem('tokenUser', tokenUser);
            commit('auth_success', tokenUser);
            resolve(resp)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    postSolidaryResource: ({ commit, state, rootState }, type) => {
      let solidary = _.cloneDeep(state.temporary.request)
      let structure = solidary.structure

      // The user is connected during the request
      if (_.isUndefined(solidary.password)) {
        delete solidary['password']
      }

      // Normalize Solidary Ressource before Post request
      if (!!structure.subjects && structure.subjects.length !== 0) {
        const s = _.find(structure.subjects, { id: solidary.subject })
        if (s) {
          solidary.subject = '/subjects/' + s.id;
        }

      }
      solidary.structure = '/structures/' + structure.id

      // Normalize Proofs
      let proofs = _.toArray(_.merge(solidary.proofs.mandatory, solidary.proofs.optional))
      let proofsToUpload = []
      solidary.proofs = []
      _.each(proofs, (proof) => {
        if (proof.type === 'file') {
          if (!_.isUndefined(proof.file)) {
            const p = _.find(structure.structureProofs, { id: proof.id });
            if (p) {
              proofsToUpload.push({
                structureProof: "/structure_proofs/" + p['id'],
                file: proof.file
              })
            }
          }
        } else {
          if (proof.value) {
            const p = _.find(structure.structureProofs, { id: proof.id });
            if (p) {
              solidary.proofs.push({
                id: "/structure_proofs/" + p['id'],
                value: proof.value
              })
            }
          }
        }
      })

      // Normalize Needs
      let needs = solidary.needs
      solidary.needs = []
      _.each(needs, (need) => {
        if (need.value) {
          solidary.needs.push({ id: "/needs/" + need['id']})
        }
      })

      // Normalize Dates
      let when = solidary.when
      let format = 'YYYY-MM-DDTHH:mm:ssZ'
      delete solidary['when']

      // Punctual request
      if (solidary.frequency === 1) {
        delete solidary['days']

        // Outward at a specific date
        if (when.departure.specificDate) {
          delete solidary['outwardDeadlineDatetime']
          delete solidary['returnDeadlineDatetime']

          solidary.outwardDatetime = moment(when.departure.specificDate)
            .set({ hour: 0, minute: 0, second: 0 })
            .format(format)


          solidary.returnDatetime = moment(when.departure.specificDate)
              .set({hour: 0, minute: 0, second: 0})
              .format(format)


          // At a specific hour
          if (when.departure.specificHour) {
            let specificHour = moment(when.departure.specificHour)
            solidary.outwardDatetime = moment(solidary.outwardDatetime)
              .set({ hour: specificHour.hour(), minute: specificHour.minute(), second: 0 })
              .format(format)

            delete solidary['marginDuration']
            delete solidary['returnMarginDuration']
          }

          // At a margin hour
          if (when.departure.marginHour) {
            let marginHour = when.departure.marginHour
            if (marginHour === 'morning') {
              marginHour = moment(structure.mMinTime).utc().get('hours')
              solidary.marginDuration = (moment(structure.mMaxTime).utc().get('hours') - moment(structure.mMinTime).utc().get('hours')) / 2 * 60 * 60
            }
            if (marginHour === 'afternoon') {
              marginHour = moment(structure.aMinTime).utc().get('hours')
              solidary.marginDuration = (moment(structure.aMaxTime).utc().get('hours') - moment(structure.aMinTime).utc().get('hours')) / 2 * 60 * 60
            }
            if (marginHour === 'evening') {
              marginHour = moment(structure.eMinTime).utc().get('hours')
              solidary.marginDuration = (moment(structure.eMaxTime).utc().get('hours') - moment(structure.eMinTime).utc().get('hours')) / 2 * 60 * 60
            }

            solidary.outwardDatetime = moment(solidary.outwardDatetime)
              .set({ hour: marginHour })
              .add(solidary.marginDuration, 'seconds')
              .format(format)
          }

          // And return at a specific hour
          if (when.return.specificHour) {
            let specificHour = moment(when.return.specificHour)
            solidary.returnDatetime = moment(solidary.returnDatetime)
              .set({ hour: specificHour.hour(), minute: specificHour.minute(), second: 0 })
              .format(format)
          }

          // And return n hours after
          if (when.return.marginHour) {
            let marginHour = when.return.marginHour;
            if (marginHour === 'morning') {
              marginHour = moment(structure.mMinTime).utc().get('hours')
              solidary.returnMarginDuration = (moment(structure.mMaxTime).utc().get('hours') - moment(structure.mMinTime).utc().get('hours')) / 2 * 60 * 60
            }
            if (marginHour === 'afternoon') {
              marginHour = moment(structure.aMinTime).utc().get('hours')
              solidary.returnMarginDuration = (moment(structure.aMaxTime).utc().get('hours') - moment(structure.aMinTime).utc().get('hours')) / 2 * 60 * 60
            }
            if (marginHour === 'evening') {
              marginHour = moment(structure.eMinTime).utc().get('hours')
              solidary.returnMarginDuration = (moment(structure.eMaxTime).utc().get('hours') - moment(structure.eMinTime).utc().get('hours')) / 2 * 60 * 60
            }
            if (marginHour === 'no-need') {
              delete solidary['returnDatetime']
              marginHour = undefined
            }
            if (!_.isUndefined(marginHour)) {
              solidary.returnDatetime = moment(solidary.returnDatetime)
                .set({ hour: marginHour })
                .add(solidary.returnMarginDuration, 'seconds')
                .format(format)
            }
          } else {
            delete solidary.returnMarginDuration;
          }
        }

        // Outward at a range date
        if (when.departure.marginDate) {
          solidary.outwardDatetime = moment()
            .set({ hour: 0, minute: 0, second: 0 })
            .format(format)
          solidary.returnDatetime = moment()
            .set({ hour: 0, minute: 0, second: 0 })
            .format(format)

          let marginDate = when.departure.marginDate
          if (marginDate === 'this-week') {
            solidary.outwardDeadlineDatetime = moment(solidary.outwardDatetime)
              .add(1, 'weeks')
              .format(format)
            if (solidary.returnDatetime) {
              solidary.returnDeadlineDatetime = moment(solidary.returnDatetime)
                .add(1, 'weeks')
                .format(format)
            }

          }
          if (marginDate === 'in-two-weeks') {
            solidary.outwardDeadlineDatetime = moment(solidary.outwardDatetime)
              .add(2, 'weeks')
              .format(format)
            if (solidary.returnDatetime) {
              solidary.returnDeadlineDatetime = moment(solidary.returnDatetime)
                .add(2, 'weeks')
                .format(format)
            }
          }
          if (marginDate === 'in-month') {
            solidary.outwardDeadlineDatetime = moment(solidary.outwardDatetime)
              .add(1, 'months')
              .format(format)
            if (solidary.returnDatetime) {
              solidary.returnDeadlineDatetime = moment(solidary.returnDatetime)
                .add(1, 'months')
                .format(format)
            }
          }

          // At a specific hour
          if (when.departure.specificHour) {
            let specificHour = moment(when.departure.specificHour)
            solidary.outwardDatetime = moment(solidary.outwardDatetime)
              .set({ hour: specificHour.hour(), minute: specificHour.minute(), second: 0 })
              .format(format)
            solidary.outwardDeadlineDatetime = moment(solidary.outwardDeadlineDatetime)
              .set({ hour: specificHour.hour(), minute: specificHour.minute(), second: 0 })
              .format(format)

            delete solidary['marginDuration']
            delete solidary['returnMarginDuration']
          }

          // At a margin hour
          if (when.departure.marginHour) {
            let marginHour = when.departure.marginHour
            if (marginHour === 'morning') {
              marginHour = moment(structure.mMinTime).utc().get('hours')
              solidary.marginDuration = (moment(structure.mMaxTime).utc().get('hours') - moment(structure.mMinTime).utc().get('hours')) / 2 * 60 * 60
            }
            if (marginHour === 'afternoon') {
              marginHour = moment(structure.aMinTime).utc().get('hours')
              solidary.marginDuration = (moment(structure.aMaxTime).utc().get('hours') - moment(structure.aMinTime).utc().get('hours')) / 2 * 60 * 60
            }
            if (marginHour === 'evening') {
              marginHour = moment(structure.eMinTime).utc().get('hours')
              solidary.marginDuration = (moment(structure.eMaxTime).utc().get('hours') - moment(structure.eMinTime).utc().get('hours')) / 2 * 60 * 60
            }

            solidary.outwardDatetime = moment(solidary.outwardDatetime)
              .set({ hour: marginHour })
              .add(solidary.marginDuration, 'seconds')
              .format(format)
            solidary.outwardDeadlineDatetime = moment(solidary.outwardDeadlineDatetime)
              .set({ hour: marginHour })
              .add(solidary.marginDuration, 'seconds')
              .format(format)
          }

          // And return at a specific hour
          if (when.return.specificHour) {
            let specificHour = moment(when.return.specificHour)
            solidary.returnDatetime = moment(solidary.returnDatetime)
              .set({ hour: specificHour.hour(), minute: specificHour.minute(), second: 0 })
              .format(format)
            solidary.returnDeadlineDatetime = moment(solidary.returnDeadlineDatetime)
              .set({ hour: specificHour.hour(), minute: specificHour.minute(), second: 0 })
              .format(format)
          }

          // And return n hours after
          if (when.return.marginHour) {
            let marginHour = when.return.marginHour
            if (marginHour === 'morning') {
              marginHour = moment(structure.mMinTime).utc().get('hours')
              solidary.returnMarginDuration = (moment(structure.mMaxTime).utc().get('hours') - moment(structure.mMinTime).utc().get('hours')) / 2 * 60 * 60
            }
            if (marginHour === 'afternoon') {
              marginHour = moment(structure.aMinTime).utc().get('hours')
              solidary.returnMarginDuration = (moment(structure.aMaxTime).utc().get('hours') - moment(structure.aMinTime).utc().get('hours')) / 2 * 60 * 60
            }
            if (marginHour === 'evening') {
              marginHour = moment(structure.eMinTime).utc().get('hours')
              solidary.returnMarginDuration = (moment(structure.eMaxTime).utc().get('hours') - moment(structure.eMinTime).utc().get('hours')) / 2 * 60 * 60
            }
            if (marginHour === 'no-need') {
              delete solidary['returnDatetime']
              marginHour = undefined
            }
            if (!_.isUndefined(marginHour)) {
              solidary.returnDatetime = moment(solidary.returnDatetime)
                .set({ hour: marginHour })
                .add(solidary.returnMarginDuration, 'seconds')
                .format(format)
              solidary.returnDeadlineDatetime = moment(solidary.returnDeadlineDatetime)
                .set({ hour: marginHour })
                .add(solidary.returnMarginDuration, 'seconds')
                .format(format)
            }
          }
        }

      }

      // Regular Request
      if (solidary.frequency === 2) {
        solidary.outwardDatetime = moment(when.departure.specificDate)
          .set({ hour: 0, minute: 0, second: 0 })
          .format(format)

        solidary.outwardDeadlineDatetime = moment(when.return.specificDate)
          .set({ hour: 0, minute: 0, second: 0 })
          .format(format)

        if (when.return.specificDate && (when.return.specificHour || when.return.marginHour)) {
          solidary.returnDatetime = moment(when.departure.specificDate)
            .set({ hour: 0, minute: 0, second: 0 })
            .format(format)

          solidary.returnDeadlineDatetime = moment(when.return.specificDate)
            .set({ hour: 0, minute: 0, second: 0 })
            .format(format)
        }

        // Departure at a specific hour
        if (when.departure.specificHour) {
          let specificHour = moment(when.departure.specificHour)
          solidary.outwardDatetime = moment(solidary.outwardDatetime)
            .set({ hour: specificHour.hour(), minute: specificHour.minute(), second: 0 })
            .format(format)
          solidary.outwardDeadlineDatetime = moment(solidary.outwardDeadlineDatetime)
            .set({ hour: specificHour.hour(), minute: specificHour.minute(), second: 0 })
            .format(format)

          let outwardTimes = {};
          Object.entries(solidary.days).forEach(item => {
            if (item[1] == 1) {
              outwardTimes[item[0]] = specificHour.format('hh:mm')
            } else if (item[1] == 0) {
              outwardTimes[item[0]] = null
            }
          });
          solidary.outwardTimes = outwardTimes;

          delete solidary['marginDuration']
          delete solidary['returnMarginDuration']
        }

        // Departure at a margin hour
        if (when.departure.marginHour) {
          let marginHour = when.departure.marginHour
          if (marginHour === 'morning') {
            marginHour = moment(structure.mMinTime).utc().get('hours')
            solidary.marginDuration = (moment(structure.mMaxTime).utc().get('hours') - moment(structure.mMinTime).utc().get('hours')) / 2 * 60 * 60
          }
          if (marginHour === 'afternoon') {
            marginHour = moment(structure.aMinTime).utc().get('hours')
            solidary.marginDuration = (moment(structure.aMaxTime).utc().get('hours') - moment(structure.aMinTime).utc().get('hours')) / 2 * 60 * 60
          }
          if (marginHour === 'evening') {
            marginHour = moment(structure.eMinTime).utc().get('hours')
            solidary.marginDuration = (moment(structure.eMaxTime).utc().get('hours') - moment(structure.eMinTime).utc().get('hours')) / 2 * 60 * 60
          }

          solidary.outwardDatetime = moment(solidary.outwardDatetime)
            .set({ hour: marginHour })
            .add(solidary.marginDuration, 'seconds')
            .format(format)
          solidary.outwardDeadlineDatetime = moment(solidary.outwardDeadlineDatetime)
            .set({ hour: marginHour })
            .add(solidary.marginDuration, 'seconds')
            .format(format)

          let outwardTimes = {};
          Object.entries(solidary.days).forEach(item => {
            if (item[1] == 1) {
              outwardTimes[item[0]] = moment().set({ hour: marginHour, minute: 0 }).add(solidary.marginDuration, 'seconds').format('hh:mm')
            } else if (item[1] == 0) {
              outwardTimes[item[0]] = null
            }
          });
          solidary.outwardTimes = outwardTimes;
        }

        // And return at a specific hour
        if (when.return.specificHour) {
          let specificHour = moment(when.return.specificHour)
          solidary.returnDatetime = moment(solidary.returnDatetime)
            .set({ hour: specificHour.hour(), minute: specificHour.minute(), second: 0 })
            .format(format)
          solidary.returnDeadlineDatetime = moment(solidary.returnDeadlineDatetime)
            .set({ hour: specificHour.hour(), minute: specificHour.minute(), second: 0 })
            .format(format)

          let returnTimes = {};
          Object.entries(solidary.days).forEach(item => {
            if (item[1] == 1) {
              returnTimes[item[0]] = specificHour.format('hh:mm')
            } else if (item[1] == 0) {
              returnTimes[item[0]] = null
            }
          });
          solidary.returnTimes = returnTimes;
        }

        // And return n hours after
        if (when.return.marginHour) {
          let marginHour = when.return.marginHour
          if (marginHour === 'morning') {
            marginHour = moment(structure.mMinTime).utc().get('hours')
            solidary.returnMarginDuration = (moment(structure.mMaxTime).utc().get('hours') - moment(structure.mMinTime).utc().get('hours')) / 2 * 60 * 60
          }
          if (marginHour === 'afternoon') {
            marginHour = moment(structure.aMinTime).utc().get('hours')
            solidary.returnMarginDuration = (moment(structure.aMaxTime).utc().get('hours') - moment(structure.aMinTime).utc().get('hours')) / 2 * 60 * 60
          }
          if (marginHour === 'evening') {
            marginHour = moment(structure.eMinTime).utc().get('hours')
            solidary.returnMarginDuration = (moment(structure.eMaxTime).utc().get('hours') - moment(structure.eMinTime).utc().get('hours')) / 2 * 60 * 60
          }
          if (marginHour === 'no-need') {
            delete solidary['returnDatetime']
            marginHour = undefined
          }
          if (!_.isUndefined(marginHour)) {
            solidary.returnDatetime = moment(solidary.returnDatetime)
              .set({ hour: marginHour })
              .add(solidary.returnMarginDuration, 'seconds')
              .format(format)
            solidary.returnDeadlineDatetime = moment(solidary.returnDeadlineDatetime)
              .set({ hour: marginHour })
              .add(solidary.returnMarginDuration, 'seconds')
              .format(format)


            let returnTimes = {};
            Object.entries(solidary.days).forEach(item => {
              if (item[1] == 1) {
                returnTimes[item[0]] = moment().set({ hour: marginHour, minute: 0 }).add(solidary.returnMarginDuration, 'seconds').format('hh:mm')
              } else if (item[1] == 0) {
                returnTimes[item[0]] = null
              }
            });
            solidary.returnTimes = returnTimes;
          }
        }
      }

      // console.log('days', solidary.days)
      // console.log('outwardDatetime', solidary.outwardDatetime)
      // console.log('outwardDeadlineDatetime', solidary.outwardDeadlineDatetime)
      // console.log('returnDatetime', solidary.returnDatetime)
      // console.log('returnDeadlineDatetime', solidary.returnDeadlineDatetime)
      // console.log('marginDuration', solidary.marginDuration)
      //Usual
      if (type === 'usual') {
        solidary.driver = true;
      }

      if (solidary.email === "") {
        delete solidary['email']
      }
      if (solidary.telephone === "") {
        delete solidary['telephone']
      }
      delete solidary.homeAddress.id;
      if (!solidary.homeAddress.hasOwnProperty('houseNumber')){
        solidary.homeAddress.houseNumber = null
      }
      if (!solidary.homeAddress.hasOwnProperty('street')){
        solidary.homeAddress.street = null
      }
      if (!solidary.homeAddress.hasOwnProperty('streetAddress')){
        solidary.homeAddress.streetAddress = null
      }
      if (!solidary.homeAddress.hasOwnProperty('postalCode')){
        solidary.homeAddress.postalCode = null
      }
      if (!solidary.homeAddress.hasOwnProperty('subLocality')){
        solidary.homeAddress.subLocality = null
      }

      // Post Solidary
      let endpoint = '';
      let object = null;
      // Suite à l'évolution de je donne un coup de pouce de Solidary vers carpool
      if ( type === 'usual') {
        endpoint = '/carpools';
        object = {};
        object.role = solidary.driver ? 1 : 2;
        object.oneWay = false;
        object.frequency = solidary.frequency;
        object.outwardWaypoints = [solidary.origin, solidary.destination];
        object.returnWaypoints = [solidary.destination, solidary.origin];;
        object.outwardDate = moment(solidary.outwardDatetime).format('YYYY-MM-DD');
        object.returnDate = moment(solidary.returnDatetime).format('YYYY-MM-DD');
        object.outwardTime = moment(solidary.outwardDatetime).format('HH:mm');
        object.returnTime = moment(solidary.returnDatetime).format('HH:mm');
        object.solidaryExclusive = true;
        object.comment = solidary.comment;
        object.user = {
          givenName : solidary.givenName,
          familyName : solidary.familyName,
          email: solidary.email,
          password: solidary.password,
          gender: solidary.gender,
          birthDate: solidary.birthDate,
          telephone: solidary.telephone,
          userAgreementAccepted: solidary.userAgreementAccepted,
          homeAddress: solidary.homeAddress
        };
        delete object.user.homeAddress.id;
        if (rootState.userStore.user) {
          object.userId = rootState.userStore.user.id;
        }
        object.marginDuration = solidary.marginDuration;
        object.returnMarginDuration = solidary.returnMarginDuration;
        if (object.frequency == 2) {
          object.outwardLimitDate = solidary.outwardDeadlineDatetime;
          object.returnLimitDate = solidary.returnDeadlineDatetime;
          const ot = Object.entries(solidary.outwardTimes).find(item => item[1] != null)
          const rt = Object.entries(solidary.returnTimes).find(item => item[1] != null)
          object.schedule = [
            {
              "mon" : solidary.days.mon == 1,
              "tue" : solidary.days.tue == 1,
              "wed" : solidary.days.wed == 1,
              "thu" : solidary.days.thu == 1,
              "fri" : solidary.days.fri == 1,
              "sat" : solidary.days.sat == 1,
              "sun" : solidary.days.sun == 1,
              "outwardTime" : ot ? ot[1] : null,
              "returnTime" : rt ? rt[1] : null
            }
          ];
        }

      } else {
        endpoint = '/solidaries';
        object = solidary
      }

      return new Promise((resolve, reject) => {
        http.post(endpoint, object)
          .then(resp => {
            return resp.data
          })
          .then((resp) => {
            if ( type === 'usual') {

            } else {

            }

            let promises = []
            if (type !== 'usual') {
              _.each(proofsToUpload, (proof) => {
                let formData = new FormData()
                formData.append('file', proof.file)
                formData.append('structureProof', proof.structureProof)
                formData.append('solidary', '/solidaries/' + resp['id'])
                promises.push(http.post(`/proofs`, formData))
              })
            }

            return Promise.all(promises)
          })
          .then((response) => {
            commit('postSolidaryResourceSuccess')
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    postSolidaryVolunteer: ({ commit, state, rootState }) => {
      let volunteer = _.cloneDeep(state.temporary.volunteer);
      if (rootState.userStore.user) {
        volunteer.email = rootState.userStore.user.email;
      }
      let structure = volunteer.structure;

      // The user is connected during the request
      if (_.isUndefined(volunteer.password)) {
        delete volunteer['password']
      }

      // Normalize Proofs
      let proofs = _.toArray(_.merge(volunteer.proofs.mandatory, volunteer.proofs.optional))
      let proofsToUpload = []
      volunteer.proofs = []
      _.each(proofs, (proof) => {
        if (proof.type === 'file') {
          if (!_.isUndefined(proof.file)) {
            const p = _.find(structure.structureProofs, { id: proof.id });
            if (p) {
              proofsToUpload.push({
                structureProof: "/structure_proofs/" + p['id'],
                file: proof.file
              })
            }
          }
        } else {
          if (proof.value) {
            const p = _.find(structure.structureProofs, { id: proof.id });
            if (p) {
              volunteer.proofs.push({
                id: "/structure_proofs/" + p['id'],
                value: proof.value
              })
            }
          }
        }
      })

      // No structure selected
      if (!volunteer.hasStructure) {
        delete volunteer['structure']
        volunteer.needs = []
      } else {
        volunteer.structure = '/structures/' + structure.id;

        // Normalize Needs
        let needs = structure.needs
        volunteer.needs = []
        _.each(needs, (need) => {
          if (need.value) {
            volunteer.needs.push(_.find(structure.needs, { id: need.id })['@id'])
          }
        })
      }

      let format = 'YYYY-MM-DDTHH:mm:ssZ'
      // Normalize Range
      volunteer.mMinTime = moment().set({ hour: volunteer.mMinTime, minute: 0, second: 0 }).format(format)
      volunteer.mMaxTime = moment().set({ hour: volunteer.mMaxTime, minute: 0, second: 0 }).format(format)
      volunteer.aMinTime = moment().set({ hour: volunteer.aMinTime, minute: 0, second: 0 }).format(format)
      volunteer.aMaxTime = moment().set({ hour: volunteer.aMaxTime, minute: 0, second: 0 }).format(format)
      volunteer.eMinTime = moment().set({ hour: volunteer.eMinTime, minute: 0, second: 0 }).format(format)
      volunteer.eMaxTime = moment().set({ hour: volunteer.eMaxTime, minute: 0, second: 0 }).format(format)

      delete (volunteer['mMin'])
      delete (volunteer['mMax'])
      delete (volunteer['aMin'])
      delete (volunteer['aMax'])
      delete (volunteer['eMin'])
      delete (volunteer['eMax'])

      // console.log(volunteer.mMinTime)
      // console.log(volunteer.mMaxTime)
      // console.log(volunteer.aMinTime)
      // console.log(volunteer.aMaxTime)
      // console.log(volunteer.eMinTime)
      // console.log(volunteer.eMaxTime)

      // Normalize maxDistance to meters
      volunteer.maxDistance = volunteer.maxDistance * 1000;
      // console.log(volunteer.maxDistance)

      // Post Solidary
      return new Promise((resolve, reject) => {
        http.post("/solidary_volunteers", volunteer)
          .then(resp => {
            return resp.data
          })
          .then((resp) => {
            let promises = [];
            _.each(proofsToUpload, (proof) => {
              let formData = new FormData();
              formData.append('file', proof.file);
              formData.append('structureProof', proof.structureProof);
              formData.append('solidaryVolunteer', '/solidaryVolunteer/' + resp['id']);
              promises.push(http.post(`/proofs`, formData))
            });

            return Promise.all(promises)
          })
          .then((response) => {
            commit('postSolidaryVolunteerSuccess')
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    putSolidaryVolunteer: ({ commit, state }) => {
      let volunteer = _.cloneDeep(state.volunteer);
      delete volunteer.user;
      delete volunteer.needs;
      delete volunteer.solidaries;
      delete volunteer.diaries;
      delete volunteer.validatedCandidate;
      delete volunteer.updatedDate;
      delete volunteer.newsSubscription;

      let format = 'YYYY-MM-DDTHH:mm:ss'
      // Normalize Range
      volunteer.mMinTime = moment().set({ hour: volunteer.mMinTime, minute: 0, second: 0 }).format(format)
      volunteer.mMaxTime = moment().set({ hour: volunteer.mMaxTime, minute: 0, second: 0 }).format(format)
      volunteer.aMinTime = moment().set({ hour: volunteer.aMinTime, minute: 0, second: 0 }).format(format)
      volunteer.aMaxTime = moment().set({ hour: volunteer.aMaxTime, minute: 0, second: 0 }).format(format)
      volunteer.eMinTime = moment().set({ hour: volunteer.eMinTime, minute: 0, second: 0 }).format(format)
      volunteer.eMaxTime = moment().set({ hour: volunteer.eMaxTime, minute: 0, second: 0 }).format(format)

      delete (volunteer['mMin'])
      delete (volunteer['mMax'])
      delete (volunteer['aMin'])
      delete (volunteer['aMax'])
      delete (volunteer['eMin'])
      delete (volunteer['eMax'])

      volunteer.maxDistance = volunteer.maxDistance * 1000;

      // Put Solidary
      return new Promise((resolve, reject) => {
        http.put("/solidary_volunteers/" + volunteer.id, volunteer)
          .then(resp => {
            return resp.data
          })
          .then((response) => {
            commit('putSolidaryVolunteerSuccess', response.data);
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  },
  getters: {
    getParameters: () => (string, separator) => {
      return _.split(string, separator)
    },
    getOrderedProofs: () => (proofs) => {
      return _.orderBy(proofs, ['position'], ['asc'])
    },
    getMandatoryProofs: (state, getters) => (proofs, type) => {
      return getters.getOrderedProofs(_.filter(proofs, (proof) => {
        return proof.mandatory && proof.type == type
      }))
    },
    getOptionalProofs: (state, getters) => (proofs, type) => {
      return getters.getOrderedProofs(_.filter(proofs, (proof) => {
        return !proof.mandatory && proof.type == type
      }))
    },
    getAddressToDisplay: () => (address) => {
      if (address) {
        let displayGeo = '';
        if (address.addressLocality) {
          displayGeo += `${address.addressLocality}`;
        }
        if (address.streetAddress) {
          displayGeo += `, ${address.streetAddress}`
        }
        if (address.postalCode) {
          displayGeo += `, ${address.postalCode}`
        }
        if (address.addressCountry) {
          displayGeo += `, ${address.addressCountry}`;
        }
        return displayGeo;
      }
      return
    },
    getRequestSubjectToDisplay: () => (request) => {
      if (!_.isUndefined(request)) {
        if (!!request.structure.subjects && !_.isUndefined(request.subject) && !_.isUndefined(request.structure)) {
          return _.find(request.structure.subjects, { 'id': request.subject }).label.toLowerCase()
        }
      }
      return
    },
    getLabelForKeyToDisplay: () => (values, key) => {
      if (!_.isUndefined(values) && !_.isUndefined(key)) {
        const f = _.find(values, { 'value': key })
        if (f) {
          return f.label.toLowerCase()
        }
      }
      return
    },
    getHourForKeyToDisplay: () => (values, key) => {
      if (!_.isUndefined(values) && !_.isUndefined(key)) {
        const f = _.find(values, { 'value': key })
        if (f) {
          return 'entre ' + moment(f.min_time).utc().format('HH') + 'h et ' + moment(f.max_time).utc().format('HH') + 'h'
        }
      }
      return
    },
    getUserAvatar: () => (user) => {
      if (user) {
        if (user.avatars.length !== 0) {
          return process.env.VUE_APP_API_URL + user.avatars[0]
        }
      }
      return
    },
    getUserAddress: () => (user) => {
      if (user) {
        if (user.addresses.length !== 0) {
          return user.addresses[0]
        }
      }
      return
    },
    getUserAge: () => (user) => {
      if (user) {
        const dateString = user.birthDate
        if (dateString !== '') {
          let birthday = +new Date(dateString)
          return ~~((Date.now() - birthday) / (31557600000))
        }
      }
      return
    },
    getUserCar: () => (user) => {
      if (user) {
        if (user.cars.length !== 0) {
          return user.cars[0]
        }
      }
    }
  }
}

export default { solidaryTransportStore };
