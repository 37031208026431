<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-back-button text=""></ion-back-button>
        </ion-buttons>
        <ion-title>{{$t('solidaryTransport.volunteer.title')}} <sup>2/5</sup></ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content ref="volunteer" color="primary" class="is-scrollable">
      <div class="mc-st-container">
        <div class="mc-st-form">

          <div class="mc-st-form-content">
            <div class="mc-st-form-header">
              <div class="mc-st-form-title">{{$t('solidaryTransport.volunteer.steps.check')}}</div>
              <div class="mc-st-form-steps">
                <span class="mc-st-form-step is-validate"></span>
                <span class="mc-st-form-step is-active"></span>
                <span class="mc-st-form-step"></span>
                <span class="mc-st-form-step"></span>
                <span class="mc-st-form-step"></span>
              </div>
            </div>

            <template v-if="structures">

              <!--
              <ion-radio-group>
              <ion-item class="mc-st-form-item" lines="none">
                <ion-radio class="mc-st-form-checkbox" :value="false" :checked="volunteer.hasStructure === false" color="success" slot="start" @ionSelect="changeHasStructure(false)"
                ></ion-radio>
                <ion-label class="mc-st-form-label no-white-space" color="primary">{{$t('solidaryTransport.volunteer.form.fields.volunteerByMyself')}}</ion-label>
              </ion-item>

              <ion-item class="mc-st-form-item" lines="none">
                <ion-radio class="mc-st-form-checkbox" :value="true" :checked="volunteer.hasStructure === true" color="success" slot="start" @ionSelect="changeHasStructure(true)"
                ></ion-radio>
                <ion-label class="mc-st-form-label no-white-space" color="primary">{{$t('solidaryTransport.volunteer.form.fields.volunteerByStructure')}}</ion-label>
              </ion-item>
              </ion-radio-group>
              -->
              <ion-item class="mc-st-form-item" v-if="defaultStructureId" lines="none">
                <ion-checkbox
                  class="mc-st-form-checkbox"
                  color="success"
                  slot="start"
                  :checked="initiative"
                  :value="initiative"
                  @ionChange="initiative = $event.target.checked;"
                ></ion-checkbox>
                <ion-label class="mc-st-form-label no-white-space" color="primary">{{ $t('solidaryTransport.volunteer.form.fields.initiative') }}</ion-label>
              </ion-item>

              <!--
              <ion-item class="mc-st-form-item" v-if="!defaultStructureId">
                <ion-label class="mc-st-form-label as-title" color="primary" position="stacked">{{$t('solidaryTransport.volunteer.form.fields.structure')}} :</ion-label>
                <ion-select
                  required
                  :value="!!volunteer.structure ? volunteer.structure.id : null"
                  @ionChange="changeStructure($event)"
                  :cancel-text="$t('solidaryTransport.buttons.cancel')"
                  :ok-text="$t('solidaryTransport.buttons.validate')"
                >
                  <ion-select-option :value="structure.id" v-for="(structure, index) in structures" :key="index">{{structure.name}}</ion-select-option>
                </ion-select>
              </ion-item>
              -->

              <div  v-if="!defaultStructureId">
                <ion-radio-group class="mc-st-form-item" :value="!!volunteer.structure ? volunteer.structure.id : null" @ionChange="changeStructure($event)">
                  <ion-label class="mc-st-form-label as-title" color="primary" position="stacked">{{$t('solidaryTransport.volunteer.form.fields.structure')}} :</ion-label>
                  <ion-item class="mc-st-form-item" v-for="(structure, index) in structures" :key="index" lines="none" >
                    <ion-radio
                      class="mc-st-form-checkbox"
                      color="success"
                      slot="start"
                      mode="md"
                      :value="structure.id"
                    ></ion-radio>
                    <ion-label class="mc-st-form-label no-white-space" color="primary">{{structure.name}} <span v-if="structure.territory">[{{ structure.territory.name }}]</span></ion-label>
                  </ion-item>
                </ion-radio-group>
              </div>

              <template v-if="volunteer.structure">
                <!-- Proof -->
                <div class="mc-st-form-details">
                  <span v-if="structures.length > 1" class="mc-st-form-note">{{$t('solidaryTransport.request.form.fields.structures')}}</span>
                  <span class="mc-st-form-error is-left" v-if="v$.volunteer.structure.$error">{{$t('solidaryTransport.register.form.validators.required')}}</span>
                </div>

                <ion-item class="mc-st-form-item as-section-title" lines="none" v-if="getMandatoryProofs(volunteer.structure.structureProofs, 2).length !== 0">
                  <ion-label class="mc-st-form-label as-title" color="primary">{{$t('solidaryTransport.request.form.fields.mandatory')}} :</ion-label>
                </ion-item>


                <template v-for="(proof, index) in getMandatoryProofs(volunteer.structure.structureProofs, 2)">

                  <template v-if="proof.checkbox">
                    <ion-item class="mc-st-form-item" :key="`proof-ckeckbox-${proof.id}-${index}`" lines="none" >
                      <ion-checkbox
                        class="mc-st-form-checkbox"
                        color="success"
                        slot="start"
                        :checked="volunteer.proofs.mandatory[proof.id].value"
                        :value="volunteer.proofs.mandatory[proof.id].value"
                        @ionChange="volunteer.proofs.mandatory[proof.id].value = $event.target.checked;"
                      ></ion-checkbox>
                      <ion-label class="mc-st-form-label no-white-space" color="primary">{{ proof.label }}*</ion-label>
                    </ion-item>
                  </template>

                  <template v-if="proof.input">
                    <ion-item class="mc-st-form-item" :key="`proof-input-${proof.id}-${index}`">
                      <ion-label position="stacked">{{proof.label}}*</ion-label>
                      <ion-input
                        class="mc-st-form-input"
                        type="text"
                        :value="volunteer.proofs.mandatory[proof.id].value"
                        @input="volunteer.proofs.mandatory[proof.id].value = $event.target.value;"
                      ></ion-input>
                    </ion-item>
                  </template>

                  <template v-if="proof.selectbox">
                    <ion-item class="mc-st-form-item" :key="`proof-selectbox-${proof.id}-${index}`">
                      <ion-label class="mc-st-form-label" color="primary" position="floating">{{ proof.label }}*</ion-label>

                      <ion-select
                        required
                        :value="volunteer.proofs.mandatory[proof.id].value"
                        @ionChange="volunteer.proofs.mandatory[proof.id].value = $event.target.value;"
                        :cancel-text="$t('solidaryTransport.buttons.cancel')"
                        :ok-text="$t('solidaryTransport.buttons.validate')"
                      >
                        <ion-select-option :value="index" v-for="(value, index) in volunteer.proofs.mandatory[proof.id].options" :key="index">{{value}}</ion-select-option>
                      </ion-select>
                    </ion-item>
                  </template>

                  <template v-if="proof.radio">
                    <ion-list class="mc-st-form-item">
                      <ion-radio-group class="mc-st-form-radios">
                        <ion-list-header class="mc-st-form-radios-header">
                          <ion-label class="mc-st-form-label" color="primary">{{proof.label}}*</ion-label>
                        </ion-list-header>

                        <ion-item lines="none" class="mc-st-form-radios-item" v-for="(value, index) in volunteer.proofs.mandatory[proof.id].options" :key="index">
                          <ion-label class="ion-text-wrap">{{value}}</ion-label>
                          <ion-radio slot="start" :value="index" :checked="volunteer.proofs.mandatory[proof.id].value == index" @ionSelect="volunteer.proofs.mandatory[proof.id].value = index"></ion-radio>
                        </ion-item>
                      </ion-radio-group>
                    </ion-list>
                  </template>

                  <template v-if="proof.file">
                    <div class="mc-st-form-item as-file" :key="`proof-file-${proof.id}-${index}`">
                      <ion-label class="mc-st-form-label" color="primary">{{ proof.label }}*</ion-label>

                      <template v-if="!processing">
                        <div v-if="volunteer.proofs.mandatory[proof.id].file" class="file" style="color:black;">Votre fichier : {{volunteer.proofs.mandatory[proof.id].file.name}}</div>
                      </template>
                      <div class="mc-st-form-controls">
                        <ion-button class="mc-st-form-control as-cta" color="light" @click="$refs['proof-file-' + proof.id][0].click()">
                          <ion-icon slot="start" name="map"></ion-icon>
                          <span v-html="$t('solidaryTransport.buttons.chooseProof')"></span>
                        </ion-button>
                      </div>
                      <input :ref="'proof-file-' + proof.id" style="display: none" type="file" @change="changeProofFile($event, volunteer.proofs.mandatory[proof.id])" />

                    </div>
                  </template>

                  <div class="mc-st-form-details" v-if="v$.volunteer.proofs.mandatory && v$.volunteer.proofs.mandatory.$error">
                    <span class="mc-st-form-error is-left">{{$t('solidaryTransport.register.form.validators.required')}}</span>
                  </div>

                </template>


                <ion-item class="mc-st-form-item as-section-title" lines="none" v-if="getOptionalProofs(volunteer.structure.structureProofs, 2).length !== 0">
                  <ion-label class="mc-st-form-label as-title" color="primary">{{$t('solidaryTransport.request.form.fields.optional')}} :</ion-label>
                </ion-item>


                <template v-for="(proof, index) in getOptionalProofs(volunteer.structure.structureProofs, 2)">
                  <template v-if="proof.checkbox">
                    <ion-item class="mc-st-form-item" :key="`proofopt-checkbox-${proof.id}-${index}`" lines="none" >
                      <ion-checkbox
                        class="mc-st-form-checkbox"
                        color="success"
                        slot="start"
                        :checked="volunteer.proofs.optional[proof.id].value"
                        :value="volunteer.proofs.optional[proof.id].value"
                        @ionChange="volunteer.proofs.optional[proof.id].value = $event.target.checked;"
                      ></ion-checkbox>
                      <ion-label class="mc-st-form-label no-white-space" color="primary">{{ proof.label }}</ion-label>
                    </ion-item>
                  </template>

                  <template v-if="proof.input">
                    <ion-item class="mc-st-form-item" :key="`proofopt-input--${proof.id}-${index}`">
                      <ion-label position="stacked">{{proof.label}}</ion-label>
                      <ion-input
                        class="mc-st-form-input"
                        type="text"
                        :value="volunteer.proofs.optional[proof.id].value"
                        @input="volunteer.proofs.optional[proof.id].value = $event.target.value;"
                      ></ion-input>
                    </ion-item>
                  </template>

                  <template v-if="proof.selectbox">
                    <ion-item class="mc-st-form-item" :key="`proofopt-selectbox-${proof.id}-${index}`">
                      <ion-label class="mc-st-form-label" color="primary" position="floating">{{ proof.label }}</ion-label>

                      <ion-select
                        required
                        :value="volunteer.proofs.optional[proof.id].value"
                        @ionChange="volunteer.proofs.optional[proof.id].value = $event.target.value;"
                        :cancel-text="$t('solidaryTransport.buttons.cancel')"
                        :ok-text="$t('solidaryTransport.buttons.validate')"
                      >
                        <ion-select-option :value="index" v-for="(value, index) in volunteer.proofs.optional[proof.id].options" :key="index">{{value}}</ion-select-option>
                      </ion-select>
                    </ion-item>
                  </template>

                  <template v-if="proof.radio">
                    <ion-list class="mc-st-form-item">
                      <ion-radio-group class="mc-st-form-radios">
                        <ion-list-header class="mc-st-form-radios-header">
                          <ion-label class="mc-st-form-label" color="primary">{{proof.label}}</ion-label>
                        </ion-list-header>

                        <ion-item lines="none" class="mc-st-form-radios-item" v-for="(value, index) in volunteer.proofs.optional[proof.id].options" :key="index">
                          <ion-label class="ion-text-wrap">{{value}}</ion-label>
                          <ion-radio slot="start" :value="index" :checked="volunteer.proofs.optional[proof.id].value == index" @ionSelect="volunteer.proofs.optional[proof.id].value = index"></ion-radio>
                        </ion-item>
                      </ion-radio-group>
                    </ion-list>
                  </template>

                  <template v-if="proof.file">
                    <div class="mc-st-form-item as-file" :key="`proofopt-file-${proof.id}-${index}`">
                      <ion-label class="mc-st-form-label" color="primary">{{ proof.label }}</ion-label>

                      <template v-if="!processing">
                        <div v-if="volunteer.proofs.optional[proof.id].file" class="file" style="color:black;">Votre fichier : {{volunteer.proofs.optional[proof.id].file.name}}</div>
                      </template>
                      <div class="mc-st-form-controls">
                        <ion-button class="mc-st-form-control as-cta" color="light" @click="$refs['proofopt-file-' + proof.id][0].click()">
                          <ion-icon slot="start" name="map"></ion-icon>
                          <span v-html="$t('solidaryTransport.buttons.chooseProof')"></span>
                        </ion-button>
                      </div>
                      <input :ref="'proofopt-file-' + proof.id" style="display: none" type="file" @change="changeProofFile($event, volunteer.proofs.optional[proof.id])" />

                    </div>
                  </template>
                </template>


                <!-- End Proof -->
              </template>
            </template>

          </div>

          <div class="mc-st-form-controls in-summary" v-if="structures">
            <ion-button class="mc-st-form-control as-back" color="light" v-html="$t('solidaryTransport.buttons.back')" @click="$router.back()"></ion-button>

            <!-- :disabled="v$.$invalid"  -->
            <ion-button  class="mc-st-form-control as-back" :disabled="true" color="primary" @click="$refs['call'].click()">
              <ion-icon slot="start" name="call"></ion-icon>
              <span v-html="$t('solidaryTransport.buttons.askHelp')"></span>
              <a ref="call" :href="getSupport()" style="display:none;"></a>
            </ion-button>

            <div class="text-center or">{{$t('solidaryTransport.buttons.or')}}</div>

            <ion-button class="mc-st-form-control" color="success" v-html="$t('solidaryTransport.request.form.next')" @click="validate()"></ion-button>
          </div>

          <!--
          <div class="mc-st-form-controls with-multiple" v-if="structures">
            <ion-button class="mc-st-form-control as-back" color="light" v-html="$t('solidaryTransport.buttons.back')" @click="$router.back()"></ion-button>

            <ion-button class="mc-st-form-control" color="success" v-html="$t('solidaryTransport.buttons.next')" @click="validate()"></ion-button>
          </div>
          -->

          <div class="mc-st-loading" v-else>
            <div v-if="!error" >
              <ion-spinner name="crescent" class="mc-st-loading-spinner"></ion-spinner>
              <p class="mc-st-loading-message">{{$t('solidaryTransport.commons.loading')}}</p>
            </div>
            <div v-if="error" >
              <p class="mc-st-loading-message">{{$t('solidaryTransport.volunteer.errors.structure')}}</p>
            </div>
          </div>

        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<style lang="scss"></style>

<script>
import _ from 'lodash'
import { mapState, mapGetters } from 'vuex'
import {toast} from "../../Shared/Mixin/toast.mixin";
import {required} from '@vuelidate/validators';
import useVuelidate from "@vuelidate/core";

const mandatory = (proof) => {
  if (proof.askType != window.solidaryType) {
    return true;
  }
  if (proof.type === 'checkbox') {
    return proof.value === true
  }
  if (proof.type === 'input') {
    return !_.isEmpty(proof.value)
  }
  if (proof.type === 'selectbox' || proof.type === "radio") {
    return !_.isEmpty(proof.value)
  }
  if (proof.type === 'file') {
    return _.isObject(proof.file)
  }
  return false
};

export default {
  name: 'solidaryTransport.volunteer.check',
  setup () {
    return { v$: useVuelidate() }
  },
  components: {},
  mixins: [toast],
  data () {
    return {
      processing: false,
      updating: false,
      structures: undefined,
      error: false,
      defaultStructureId: JSON.parse(process.env.VUE_APP_SOLIDARY_DEFAULT_STRUCTURE_ID),
      initiative: false,
      support: process.env.VUE_APP_SOLIDARY_SUPPORT_HELP,
    }
  },
  computed: {
    ...mapGetters([
      'getAddressToDisplay',
      'getMandatoryProofs',
      'getOptionalProofs',
      'getParameters'
    ]),
    volunteer: {
      get() {
        return this.$store.state.solidaryTransportStore.temporary.volunteer;
      },
      set() {
        this.$store.commit("solidaryTemporyVolunteerUpdate", this.volunteer);
      }
    }
  },
  methods: {
    changeProofFile: function ($event, proof) {
      this.processing = true
      let file = $event.target.files[0]
      proof.file = file
      if (file.size <= 1000000) {
        setTimeout(() => {
          this.processing = false
        }, 0)
      } else {
        this.presentToast(this.$t("UpdateProfile.file-size"), 'danger')
      }
    },
    changeStructure: function($event) {
      let structure = _.find(this.structures, {id: parseInt($event.target.value)})
      this.$store.commit('solidaryVolunteerStructureUpdate', structure)
    },
    changeHasStructure: function (value) {
      if (!this.updating) {
        this.updating = true
        this.volunteer.hasStructure = value

        setTimeout(() => {
          this.updating = false
        }, 100)
      }
    },
    validate: function() {
      this.v$.$reset();
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.$refs.volunteer.getScrollElement().then(parent => {
          let child = document.getElementsByClassName("mc-st-form-error")[0];
          if (child) {
            var childPos = child.offsetTop;
            var parentPos = parent.offsetTop;
            var top = childPos - parentPos - 30;
            this.$refs.volunteer.scrollToPoint(0, top, 0);
          } else {
            this.$router.push({
              name: "solidaryTransport.home.volunteer.availabilities"
            });
          }
        });
      } else if (this.defaultStructureId && !this.initiative) {
        this.presentToast(
          "Vous devez confirmer que le bénévolat est de votre propre initiative pour continuer",
          "danger"
        );
      } else {
        this.$router.push({
          name: "solidaryTransport.home.volunteer.availabilities"
        });
      }
    },
    getSupport() {
      if (this.volunteer.structure && this.volunteer.structure.telephone) {
        return 'tel:' + this.volunteer.structure.telephone;
      } else {
        return this.support;
      }
    },
  },
  validations() {
    let validations = {
      volunteer: {}
    }
    if (this.structures) {
      validations.volunteer.structure =  { required }
    }
    if (this.volunteer.proofs && this.volunteer.proofs.mandatory) {
      //console.log('proofs', this.volunteer.proofs)
      validations.volunteer.proofs = {}
      validations.volunteer.proofs.mandatory =  {
        $each: {
          mandatory
        }
      }
    }
    return validations
  },
  created: function () {
    // window.solidaryType = 2;
    // this.$store.state.solidaryTransportStore.temporary.volunteer = _.cloneDeep(this.$store.state.solidaryTransportStore.default.volunteer);
    // this.$store.state.solidaryTransportStore.temporary.volunteer.hasStructure = true;

    this.$store.dispatch('getSolidaryStructuresByGeolocation', {lat: this.volunteer.homeAddress.latitude, lng: this.volunteer.homeAddress.longitude})
      .then((res) => {

        const structures = [];
        res.forEach(s => {
          s.territories.forEach(t => {
            const n = Object.assign({}, s);
            n.territory = Object.assign({}, t);
            structures.push(n);
          })
        })

        if (structures.length !== 0) {

          if (this.defaultStructureId) {
            const f = structures.find(item => item.id == this.defaultStructureId)
            if (f) {
              this.$store.commit('solidaryVolunteerStructureUpdate', _.cloneDeep(f))
            }
          } else if (!this.volunteer.structure) {
            this.$store.commit('solidaryVolunteerStructureUpdate', _.cloneDeep(structures[0]))
          }


          setTimeout(() => {
            this.structures = structures
          }, 500)
        } else {
          this.error = true;
        }


      })
      .catch((error) => {
        console.error(error)
      })
  }
}
</script>
