import http from '../Shared/Mixin/http.mixin'
import { isPlatform } from "@ionic/core"
import _ from 'lodash'
import moment from 'moment'

export const hitchHikingStore = {
  state: {
    identityFile: null
  },
  mutations: {
    hitch_hicking_update(state, value) {
      state.identityFile = value;
    },
    hitch_hicking_reset(state) {
      state.identityFile = null;
    }
  },
  actions: {
    hitch_hicking_sendVerifId: ({commit}, params) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        console.log(params)
        formData.append('file', params.file);
        http.post(`/identity_proofs`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    hitch_hicking_get_identity_proofs: ({commit}, id) => {
      return new Promise((resolve, reject) => {
        http.get(`/identity_proofs/` + id)
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
  getters: {

  }
}

export default { hitchHikingStore };
