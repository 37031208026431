



import Vue from 'vue'
import store from '../Shared/Store/store'
import _ from 'lodash'
import MainHitchHiking from './MainHitchHiking/MainHitchHiking.view.vue';
import HomeHitchHiking from "./HomeHitchHiking/HomeHitchHiking.view.vue";
import Article from "../Carpools/Article/Article.view.vue";
import RegisterHitchHiking from "./RegisterHitchHiking/RegisterHitchHiking.view.vue";
import LoginHitchHiking from "./LoginHitchHiking/LoginHitchHiking.view.vue";
import ProfileHitchHiking from "./ProfileHitchHiking/ProfileHitchHiking.view.vue";
import HitchHikingConfirmRegistration from "./RegisterHitchHiking/HitchHikingConfirmRegistration.view";
import UpdateProfile from "../Carpools/Profile/UpdateProfile.view";
import ProfileAlerts from "../Carpools/Profile/ProfileAlerts.view";
import ProfilePrefs from "../Carpools/Profile/ProfilePrefs.view";
import HitchHikingPref from "./ProfileHitchHiking/HitchHikingPref.view";


export default [
  {
    path: '',
    name: 'mainHitchHiking',
    component: MainHitchHiking,
    children: [
      {
        path: '',
        name: 'hitchHiking.redirect',
        redirect: { name: 'hitchHiking.home' }
      },
      {
        path: 'home',
        name: 'hitchHiking.home',
        component: HomeHitchHiking,
      },
      {
        path: 'help',
        name: 'hitchHiking.help',
        component: Article,
      },
      {
        path: 'register',
        name: 'hitchHiking.register',
        component: RegisterHitchHiking,
      },
      {
        path: 'login',
        name: 'hitchHiking.login',
        component: LoginHitchHiking,
      },
      {
        path: 'profile',
        name: 'hitchHiking.profile',
        component: ProfileHitchHiking,
      },
    ]
  },
  {
    path: 'confirm-registration/:email',
    name: 'hitchHiking.confirm-registration',
    component: HitchHikingConfirmRegistration,
  },
  {
    path: 'update-profile',
    name: 'hitchHiking.update-profile',
    component: UpdateProfile
  },
  {
    path: 'profile-alerts',
    name: 'hitchHiking.profile-alerts',
    component: ProfileAlerts
  },
  {
    path: 'profile-prefs',
    name: 'hitchHiking.profile-prefs',
    component: ProfilePrefs
  },
  {
    path: 'hitchhiking-prefs',
    name: 'hitchHiking.hitchhiking-prefs',
    component: HitchHikingPref
  },
]

